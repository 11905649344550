// Мобильное меню


$(function () {
    $(".fixed__hamburger").click(function () {
        $("body").toggleClass("open_menu");
    })
});
$(window).resize(function () {
    if ($(window).width() > 1260) {
        $("body").removeClass("open_menu");
    }
});

$(function () {
    $(".open__form").click(function () {
        $(".reviews__form").toggleClass("visible");
    })
});
//Фон для меню при прокрутке
var scrolled;
window.onscroll = function () {
    scrolled = window.pageYOffset || document.documentElement.scrollTop;
    if (scrolled > 2) {
        $(".fixed").addClass('fixed__scroll')
    }
    if (2 > scrolled) {
        $(".fixed").removeClass('fixed__scroll')
    }

}

$(function () {
    $('.fixed__phone').on('click', function () {
        find('.modal-call').toggleClass('collapsed');
    });
    //next dropdown1
});


$(document).ready(function () {

    $('.reviews__slider .slick-carousel').on('init', function (e, slick) {
        $('.nav > li').eq(slick.currentSlide).addClass('active');
    }).slick({
        fade: false,
        lazyLoad: 'ondemand',
        autoplay: false,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerMode: true,
        centerPadding: '120px',
        infinite: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '0px',
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '120px',
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerPadding: '20px',
                }
            }
        ]
    }).on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        $('.nav > li').removeClass('active').eq(nextSlide).addClass('active');
    }).on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        $('.nav_m > li').removeClass('active').eq(nextSlide).addClass('active');
    });

    $('.navig__reviews').on('click', '.next', function () {
        $(".slick-carousel").slick('slickNext');
    });
    $('.navig__reviews').on('click', '.prev', function () {
        $(".slick-carousel").slick('slickPrev');
    });
});

$(document).ready(function () {

    $('.slider__contacts').on('init', function (e, slick) {
        $('.nav > li').eq(slick.currentSlide).addClass('active');
    }).slick({
        fade: false,
        lazyLoad: 'ondemand',
        autoplay: false,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        responsive: [
            {
                breakpoint: 590,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 760,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    }).on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        $('.nav > li').removeClass('active').eq(nextSlide).addClass('active');
    }).on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        $('.nav_m > li').removeClass('active').eq(nextSlide).addClass('active');
    });

    $('.navig__contacts').on('click', '.next', function () {
        $(".slider__contacts").slick('slickNext');
    });
    $('.navig__contacts').on('click', '.prev', function () {
        $(".slider__contacts").slick('slickPrev');
    });
});
$(document).ready(function () {

    $('.slider__header').on('init', function (e, slick) {
        $('.nav > li').eq(slick.currentSlide).addClass('active');
    }).slick({
        fade: false,
        lazyLoad: 'ondemand',
        autoplay: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
    }).on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        $('.nav > li').removeClass('active').eq(nextSlide).addClass('active');
    }).on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        $('.nav_m > li').removeClass('active').eq(nextSlide).addClass('active');
    });

});
$(document).ready(function () {

    $('.slider__gallery').on('init', function (e, slick) {
        $('.nav > li').eq(slick.currentSlide).addClass('active');
    }).slick({
        fade: false,
        lazyLoad: 'ondemand',
        autoplay: false,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 590,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 760,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    }).on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        $('.nav > li').removeClass('active').eq(nextSlide).addClass('active');
    }).on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        $('.nav_m > li').removeClass('active').eq(nextSlide).addClass('active');
    });
    $('.navig__gallery').on('click', '.next', function () {
        $(".slider__gallery").slick('slickNext');
    });
    $('.navig__gallery').on('click', '.prev', function () {
        $(".slider__gallery").slick('slickPrev');
    });


    // Слайдер секции 1 Шапка --- НАЧАЛО ---
    $('.slider__header-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        arrows: false,
        infinite: false
    }).on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        $('.slider__header-nav > li').removeClass('active').eq(nextSlide).addClass('active');
    }).on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        $('.bg__slider').removeClass('active').eq(nextSlide).addClass('active');
    });


    $('.slider__header-nav').on('click', 'li', function () {
        $('.slider__header-for').slick('slickGoTo', $(this).index());
    });
    // Слайдер секции 1 Шапка --- КОНЕЦ ---

    // Слайдер секции 4 Персонал --- НАЧАЛО ---
    $('.slider__personal').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.left__personal-slider',
        infinite: true
    }).on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        $('.slider__header-nav2 > li').removeClass('active').eq(nextSlide).addClass('active');
    }).on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        $('.bg__slider2').removeClass('active').eq(nextSlide).addClass('active');
    });

    $('.left__personal-slider').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: '60px',
        asNavFor: '.slider__personal',
        infinite: true,
        dots: false,
        centerMode: false,
        arrows: false,
        fade: false,
        focusOnSelect: true,
        vertical: true,
        verticalSwiping: true,
    });

    $('.navig__pers').on('click', '.next', function () {
        $(".slider__personal").slick('slickNext');
    });
    $('.navig__pers').on('click', '.prev', function () {
        $(".slider__personal").slick('slickPrev');
    });

    $('.up__down').on('click', '.scroll__down_p', function () {
        $(".slider__personal").slick('slickNext');
    });
    $('.up__down').on('click', '.scroll__up_p', function () {
        $(".slider__personal").slick('slickPrev');
    });
    // Слайдер секции 4 Персонал --- КОНЕЦ ---


    // Слайдер секции 2 Соимость услуг --- НАЧАЛО ---
    $('.slider__for-s2').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        infinite: false,
        swipe: false,
        asNavFor: '.slider__nav-s2'
    });
    $('.slider__nav-s2').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        centerPadding: '60px',
        asNavFor: '.slider__for-s2',
        infinite: false,
        dots: false,
        centerMode: false,
        arrows: false,
        fade: false,
        focusOnSelect: true,
        vertical: true,
        verticalSwiping: true,
        responsive: [
            {
                breakpoint: 370,
                settings: {
                    centerPadding: '60px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    vertical: false,
                    verticalSwiping: false,
                    centerMode: true,
                }
            },
            {
                breakpoint: 570,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '20%',
                    vertical: false,
                    verticalSwiping: false,
                    centerMode: true,
                }
            }
            ,
            {
                breakpoint: 768,
                settings: {
                    centerPadding: '30%',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    vertical: false,
                    verticalSwiping: false,
                }
            }
            ,
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    centerPadding: '0',
                    padding: '40px',
                    centerMode: false,
                    slidesToScroll: 3,
                    vertical: false,
                    verticalSwiping: false,
                }
            }
        ]
    });

    // Слайдер секции 2 Соимость услуг --- КОНЕЦ ---


    // Слайдер секции 6 ИНФОРМАЦИИ --- НАЧАЛО ---
    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: false,
        infinite: false,
        swipe: false,
        adaptiveHeight: true,
        asNavFor: '.slider-nav'
    });
    $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        centerPadding: '60px',
        asNavFor: '.slider-for',
        infinite: false,
        dots: false,
        centerMode: true,
        arrows: false,
        fade: false,
        focusOnSelect: true,
        vertical: true,
        verticalSwiping: true,
        responsive: [
            {
                breakpoint: 370,
                settings: {
                    centerPadding: '25%',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    vertical: false,
                    verticalSwiping: false,
                }
            },
            {
                breakpoint: 570,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '20%',
                    vertical: false,
                    verticalSwiping: false,
                }
            }
            ,
            {
                breakpoint: 768,
                settings: {
                    centerPadding: '30%',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    vertical: false,
                    verticalSwiping: false,
                }
            }
            ,
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    centerPadding: '40px',
                    centerMode: false,
                    slidesToScroll: 3,
                    vertical: false,
                    verticalSwiping: false,
                }
            }
        ]
    });


    $('.license__slide-img').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        centerMode: true,
        centerPadding: '0',
        arrows: false,
        fade: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 430,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false
                }
            },
            {
                breakpoint: 760,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerPadding: '0',
                    centerMode: true
                }
            }
        ]
    });
    $('.license__slide-certificate').slick({
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        fade: false,
        infinite: false
    });
    // Слайдер секции 6 ИНФОРМАЦИИ --- КОНЕЦ ---
});

var header = $('.fixed'),
    scrollPrev = 0;

$(window).scroll(function () {
    var scrolled = $(window).scrollTop();

    if (scrolled > 100 && scrolled > scrollPrev) {
        header.addClass('out');
    } else {
        header.removeClass('out');
    }
    scrollPrev = scrolled;
});


$(".btn__modal").click(function () {
    $(".modal-call").addClass("visible");
    $("html").addClass("modal-open");
});
$(".closed__modal").click(function () {
    $(".modal").removeClass("visible");
    $("html").removeClass("modal-open");
});
$(".btn__modal-lic").click(function () {
    $(".modal-licenze").addClass("visible");
});
$(".closed__modal_lic").click(function () {
    $(".modal_lic").removeClass("visible");
});

// jQuery(window).scroll(function () {
//     var $sections = $('section');
//     $sections.each(function (i, el) {
//         var top = $(el).offset().top - 500;
//         var bottom = top + $(el).height();
//         var scroll = $(window).scrollTop();
//         var id = $(el).attr('id');
//         if (scroll > top && scroll < bottom) {
//             $('a.active').removeClass('active');
//             $('a[href="#' + id + '"]').addClass('active');
//             $('a[href="/stomatolog#' + id + '"]').addClass('active');
//             $('a[href="/uzi#' + id + '"]').addClass('active');
//             $('a[href="/analiz#' + id + '"]').addClass('active');
//             $('a[href="/ginekolog#' + id + '"]').addClass('active');
//
//         }
//     })
// });

// $("fixed__menu_d").on("click", "a", function (event) {
//     // исключаем стандартную реакцию браузера
//     event.preventDefault();
//
//     // получем идентификатор блока из атрибута href
//     var id = $(this).attr('href'),
//
//         // находим высоту, на которой расположен блок
//         top = $(id).offset().top;
//
//     // анимируем переход к блоку, время: 800 мс
//     $('body,html').animate({scrollTop: top}, 800);
// });
$(function () {
    var style = window.getComputedStyle(document.getElementById('h2Gallery'));
    var marginTop = style.getPropertyValue('margin-left');

    $('#s7 .slider__gallery').css('margin-left', marginTop);

    $(window).resize(function () {
        var style = window.getComputedStyle(document.getElementById('h2Gallery'));
        var marginTop = style.getPropertyValue('margin-left');

        $('#s7 .slider__gallery').css('margin-left', marginTop);
    });
});
$(function () {
    for (let i = 0; i <= 50; i++) {
        $('.price__block-item-' + i).wrapAll("<div class='price__block-spoiler price__block-spoiler-" + i + "'></div>");
        $('.price__block-spoiler-' + i).wrapAll("<div class='spoiler-block spoiler-block-" + i + "'></div>");
        $('.spoiler-block-' + i + ', .title_spoiler-' + i).wrapAll("<div class='price__block-item spoiler'></div>");
    }
});

$(function () {
    $('.spoiler__span').on('click', function () {
        var block = $(this).closest('.price__block-item');
        block.find('.spoiler__span').toggleClass('collapsed');
        block.find('.spoiler-block').slideToggle(400)
    });
    //next dropdown1
});
$(function () {
    var scrollone = document.getElementById('scroll__spoiler_one'),
        prevone = document.getElementById('scroll__up_one'),
        nextone = document.getElementById('scroll__down_one');

    prevone.addEventListener('click', function () {
        scrollone.scrollBy({top: -50, behavior: "smooth"});
    }, true);
    nextone.addEventListener('click', function () {
        scrollone.scrollBy({top: 50, behavior: "smooth"});
    }, true);
});
//Прокрутка цен вниз/вверх
$(function () {
    var scroll = document.getElementById('scroll__spoiler_1'),
        scroll2 = document.getElementById('scroll__spoiler_2'),
        scroll3 = document.getElementById('scroll__spoiler_3'),
        prev = document.getElementById('scroll__up'),
        next = document.getElementById('scroll__down'),
        scrollli1 = document.getElementById('scroll__li_1'),
        scrollli2 = document.getElementById('scroll__li_2'),
        scrollli3 = document.getElementById('scroll__li_3');

    scrollli1.addEventListener('click', function () {
        scroll2.scrollBy({top: -500, behavior: "smooth"});
        scroll3.scrollBy({top: -500, behavior: "smooth"});
    }, true);
    scrollli2.addEventListener('click', function () {
        scroll.scrollBy({top: -500, behavior: "smooth"});
        scroll3.scrollBy({top: -500, behavior: "smooth"});
    }, true);
    scrollli3.addEventListener('click', function () {
        scroll.scrollBy({top: -500, behavior: "smooth"});
        scroll2.scrollBy({top: -500, behavior: "smooth"});
    }, true);
    prev.addEventListener('click', function () {
        scroll.scrollBy({top: -50, behavior: "smooth"});
        scroll2.scrollBy({top: -50, behavior: "smooth"});
        scroll3.scrollBy({top: -50, behavior: "smooth"});
    }, true);
    next.addEventListener('click', function () {
        scroll.scrollBy({top: 50, behavior: "smooth"});
        scroll2.scrollBy({top: 50, behavior: "smooth"});
        scroll3.scrollBy({top: 50, behavior: "smooth"});
    }, true);
});


// $('.scroll__spoiler').scroll(function () {
//     var height = $('.scroll__spoiler').scrollTop();
//     /*Если сделали скролл на 100px задаём новый класс для header*/
//     if (height > 1) {
//         $('#scroll__up').removeClass('stop');
//     } else {
//         /*Если меньше 100px удаляем класс для header*/
//         $('#scroll__up').addClass('stop');
//     }
// });

// (function ($) {
//     $(window).on("load", function () {
//         $("#scroll__spoiler_one").mCustomScrollbar({
//             theme: "dark",
//             scrollButtons: {enable: true}
//         });
//
//         $(".header__col3").mCustomScrollbar({
//             theme: "dark",
//             axis:"x"
//         });
//
//     });
// })(jQuery);

// function sayHi() {
//     // $('#scroll__down_one').click(function() {
//     //     alert("click on .item-text - " + $(this).text());
//     // });
//     $('#scroll__down_one').click(function () {
//         // $(this).closest('#scroll__spoiler_one').find('.mCSB_buttonDown').click();
//          //$('.scrolldgdfg').animate({ top: "+=250px" }, 0);​
//     });
// }

// setTimeout(sayHi, 2000);

//
// $("#scroll__down_one").click(function () {
//     $('#mCSB_1_container').offset(function(i, coord){
//         var  newCoord = {};
//         newCoord.top = coord.top + 10;
//         return newCoord;
//     });
// });
